<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="预约工单" />
    <div class="search">
      <van-radio-group style="font-size:14px" icon-size="16px" v-model="dateType" direction="horizontal">
        <van-radio name="1">七天</van-radio>
        <van-radio name="2">近一月</van-radio>
        <van-radio name="3">自定义</van-radio>
        <van-radio name="4">全年</van-radio>
        <van-button type="primary" size="mini" @click="searchFn">查询</van-button>
      </van-radio-group>
    </div>
    <van-cell-group class="date-cell-group" v-show="dateType === '3'">
      <van-cell title="选择日期区间" :value="date" @click="show = true" is-link />
      <van-calendar v-model="show" type="range" :min-date="minDate" @confirm="onConfirm" />
    </van-cell-group>
    <div>
      <van-row>
        <van-col span="24">
          <div style="width: auto;height: 400px;" class="payMoney" id="today" @click="toWebsiteFn"></div>
        </van-col>
      </van-row>
    </div>
    <van-overlay :show="isshow">
      <div class="wrapper">
        <van-loading color="#0094ff" size="24px" vertical>加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getUrlKey } from '@/utils/common'
export default {
  data() {
    return {
      dateType: '1',
      date: '',
      show: false,
      minDate: new Date(2020, 1, 1),
      endDate: '',
      startDate: '',
      isshow: false
    }
  },
  created() {
    this.searchFn()
  },
  methods: {
    toWebsiteFn(e) {
      if (e.target.nodeName === 'DIV' || e.target.nodeName === 'SPAN') {
        console.log(e)
        let time =
          e.path.length > 14
            ? e.path[7].innerText.split('安装工单量')[0].split('\n')[0]
            : e.path[4].innerText.split('安装工单量')[0].split('\n')[0]
        this.$router.push({
          path: '/reservation/website',
          query: {
            time,
            type: this.dateType
          }
        })
      }
    },
    //折线图
    echartsFn(id, value) {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById(id))
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById(id))
      }
      myChart.setOption(
        {
          title: {
            text: value.title
          },
          tooltip: {
            trigger: 'axis',
            triggerOn: 'click',
            enterable: true
            // formatter: function (params) {
            //   console.log(params, this);
            //   // if (params.seriesType === `line`) {
            //   //   setTimeout(() => {
            //   //     this.hookToolTip = params.name;
            //   //   }, 1000);
            //   // }
            //   return "{b0}";
            // }.bind(this),
          },
          legend: {
            data: value.rightTitle,
            top: '6%'
          },
          grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: value.ddate,
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              interval: 'auto',
              rotate: '45'
            },
            axisTick: {
              inside: true
            }
          },
          yAxis: {
            type: 'value'
          },
          series: value.ser
        },
        true
      )
      myChart.on('click', function (params) {
        console.log(params, 1111)
      })
    },
    //折线图数据请求
    async installEcharts(params, value) {
      let installRes = await this.postRequest('/wechat-analysis/summarys', { sumMode: 'install.reserve', ...params })
      let repairRes = await this.postRequest('/wechat-analysis/summarys', { sumMode: 'repair.reserve', ...params })
      //   console.log(repairRes)
      let date = []
      let azgdl = []
      let azsbs = []
      let wxgdl = []
      let wxsbs = []
      installRes.data.forEach(v => {
        date.push(v.ddate)
        azgdl.push(v.orderct)
        azsbs.push(v.machinect)
      })
      repairRes.data.forEach(v => {
        // date.push(v.ddate)
        wxgdl.push(v.orderct)
        wxsbs.push(v.machinect)
      })
      //   if (date.length > 20) {
      //     let a = date.map((v, i) => {
      //       if ((i + 1) % 7 === 1) {
      //         return v
      //       } else {
      //         return ''
      //       }
      //     })
      //     date = a
      //   }
      //   console.log(date)
      if (value === 'today') {
        let obj = {
          title: '预约工单量',
          rightTitle: ['安装工单量', '安装设备数', '维修工单量', '维修设备数'],
          ddate: date,
          ser: [
            {
              name: '安装工单量',
              type: 'line',
              data: azgdl
            },
            {
              name: '安装设备数',
              type: 'line',
              data: azsbs
            },
            {
              name: '维修工单量',
              type: 'line',
              data: wxgdl
            },
            {
              name: '维修设备数',
              type: 'line',
              data: wxsbs
            }
          ]
        }
        this.echartsFn('today', obj)
        this.isshow = false
      }
    },

    //查询
    async searchFn() {
      this.isshow = true
      let params = {
        openid: getUrlKey('openid', window.location.href),
        moduleId: '11',
        type: '家用'
      }
      let obj = {}
      if (this.dateType === '4') {
        let nowDate = new Date()
        let date = {
          // 获取当前年份
          year: nowDate.getFullYear(),
          //获取当前月份
          month: nowDate.getMonth() + 1,
          //获取当天
          day: nowDate.getDate()
        }
        let start = date.year + '/01/01'
        let end = date.year + '/' + (date.month > 9 ? date.month : '0' + date.month) + '/' + (date.day > 9 ? date.day : '0' + date.day)
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: 'month',
          sumType: 0
        }
      } else if (this.dateType === '3') {
        let start = this.startDate
        let end = this.endDate
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: 'day',
          sumType: 0
        }
      } else if (this.dateType === '2') {
        let start = this.dateUtils.getBeforeDate(31)
        // let end = this.dateUtils.formatDate(new Date());
        let end = this.dateUtils.getBeforeDate(1)
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: 'day',
          sumType: 0
        }
      } else {
        let start = this.dateUtils.getBeforeDate(7)
        // let end = this.dateUtils.formatDate(new Date())
        let end = this.dateUtils.getBeforeDate(-1)
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: 'day',
          sumType: 0
        }
      }
      //   let res = await this.postRequest('/wechat-analysis/summarys', { sumMode: 'install.reserve', ...params, ...obj })
      this.installEcharts({ ...params, ...obj }, 'today')
    },
    //日期区间
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${
        date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
      }`
    },
    onConfirm(date) {
      const [start, end] = date
      this.show = false
      this.startDate = this.formatDate(start)
      this.endDate = this.formatDate(end)
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang='scss' scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.search {
  background-color: #fff;
  padding: 10px 0;
  margin-top: 10px;
  .van-radio-group {
    justify-content: space-around;
    align-items: center;
  }
}
.payMoney {
  background-color: #fff;
  margin-top: 10px;
  padding-top: 10px;
}
</style>